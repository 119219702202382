<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.customerName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row >
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>No Form # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.formNumber }}</span>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.purchaseOrderNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>P{{ form.invoiceNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ formatDate(form.invoiceDate) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Term</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.invoiceDuration }} days</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Bill To<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.billTo }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bill To Contact</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ contactName(form.billToContact) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percentage Invoice<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.percentageInvoice }}%</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPN</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.percentagePpn }}%</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>Rp. {{ form.rate }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox v-model="form.fp" label="Faktur Pajak Digunggung" disabled></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0 && !form.fp">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.taxInvoiceSerialNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row >
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Material Doc #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.materialDocumentNumber }}</span>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3">
                <v-subheader>DP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.dp" disabled></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.products"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.products.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ formatDate(form.invoiceDueDate) }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Site</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.site }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Sales</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.salesName }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Sub Total</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Invoice</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterInvoice) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="form.percentagePpn > 0">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import moment from "moment";
export default {
  name: "detail-sale-proforma",
  props: {
    form: Object,
    id: Number,
    contacts: Array,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "partDesc",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "quantity",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Line Item",
        value: "lineItem",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      // {
      //   text: "DO#",
      //   value: "doNumber",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Mat Doc#",
      //   value: "materialDocumentNumber",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Account",
      //   value: "accountName",
      //   sortable: false,
      //   divider: true,
      // },
    ],
  }),

  methods: {
    contactName(id) {
      const contact = this.contacts.find(x => x.id === id);
      const name = contact ? contact.name : "";
      return name;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>

<style></style>
