<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="saleProforma"
          :customers="customers"
          :salePurchaseOrders="salePurchaseOrders"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :form="saleProforma" :contacts="contacts" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="routeToPrintPreview">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Print Preview</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="saleProforma.invoiceNumber ? saleProforma.invoiceNumber.toString() : ''"
      title="Sale Proforma"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
    <dialog-remark
      :dialog="dialogRemark"
      :form="formRemark"
      @save="updateRemark"
      @close="close"
    ></dialog-remark>
  </form>
</template>

<script>
import Detail from "./DetailSaleProforma";
import FormEdit from "./FormSaleProforma";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";
import DialogRemark from "@/components/DialogRemark";

export default {
  name: "sale-proforma-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
    DialogRemark,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    dialogRemark: false,
    salePurchaseOrders: [],
    accounts: [],
    saleProforma: {},
    formRemark: {},
  }),
  computed: {
    ...mapState("external", ["customers", "contacts"]),
  },
  methods: {
    async getSaleProforma() {
      const response = await this.$store.dispatch("saleProforma/getById", this.id);
      if (response.status === 200) {
        this.saleProforma = response.data;
        this.$store.dispatch("external/getContact", response.data.customerId);
      }
      this.$store.dispatch("external/getCustomers");
      this.getSalePurchaseOrders();
      this.getAccounts();
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getSalePurchaseOrders() {
      const response = await this.$store.dispatch("external/getSalePurchaseOrdersById", {
        id: this.id,
        type: "DP",
      });
      if (response.status === 200) {
        this.salePurchaseOrders = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("saleProforma/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogRemark = false;
    },
    async updateRemark(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleProforma/updateRemark", item)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    openRemark() {
      this.dialogRemark = true;
    },
    routeToPrintPreview() {
      this.$router.push({ name: "Print Preview Sale Proforma", params: { id: this.id } });
    },
    cancel() {
      this.getSaleProforma();
      this.edit = true;
    },
  },
  mounted() {
    this.getSaleProforma();
  },
  beforeMount() {
    this.formRemark = {
      remark: "",
      type: "Proforma",
      transactionId: this.id,
    };
  },
};
</script>

<style></style>
